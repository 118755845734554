import { default as _91_46_46_46slug_93GC0cXHahTLMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-cms/pages/[...slug].vue?macro=true";
import { default as addressesFA4zYmifppMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/addresses.vue?macro=true";
import { default as companyzydbg0cTlLMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/company.vue?macro=true";
import { default as contactQLuI1rxfTEMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/contact.vue?macro=true";
import { default as indexrXrvy7ICtBMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/index.vue?macro=true";
import { default as listsy6b9IcpnjyMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/lists.vue?macro=true";
import { default as _91variantId_93jyvOZDhnyWMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/offer/[id]/[variantId].vue?macro=true";
import { default as indexJo3UtOHJVrMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/offers/index.vue?macro=true";
import { default as _91_46_46_46slug_93V4yKoYOwakMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue?macro=true";
import { default as orderskKP5yrb6FcMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/orders.vue?macro=true";
import { default as payments_45and_45billingAcwWK8t5ssMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue?macro=true";
import { default as add_45user105NWPRRZwMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue?macro=true";
import { default as edit5FginiPqg9Meta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue?macro=true";
import { default as indexpOHNvJ0Y1GMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue?macro=true";
import { default as newDj1OCi6BAbMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue?macro=true";
import { default as edithyVEzR5ZBqMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue?macro=true";
import { default as indexePQEtlkT5NMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue?macro=true";
import { default as profile841olOrNBpMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/profile.vue?macro=true";
import { default as accountBhLZjRBUFLMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account.vue?macro=true";
import { default as _91slug_93eQkW2qJTC1Meta } from "/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue?macro=true";
import { default as category6cZNvaUMeAMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/category.vue?macro=true";
import { default as cartdniQ1d6g8eMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue?macro=true";
import { default as delivery_45and_45paymentCDsqvTYrKaMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue?macro=true";
import { default as summaryzG6fw341tCMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue?macro=true";
import { default as checkoutLhZ1rKIAaIMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/checkout.vue?macro=true";
import { default as createPasswordeolWbCZSfiMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue?macro=true";
import { default as resetPassword0nUElijrt4Meta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue?macro=true";
import { default as indexwgIfUSq7TcMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/event/index.vue?macro=true";
import { default as indexsD1z228m9YMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/newsletter/index.vue?macro=true";
import { default as productlKqbvJQ5TpMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/product.vue?macro=true";
import { default as registerl1WuNp4oqMMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/register.vue?macro=true";
import { default as resolveWUiEKTzX7IMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/resolve.vue?macro=true";
import { default as cmsyh3P5s3Bs3Meta } from "/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/search/cms.vue?macro=true";
import { default as storeIKRif2aTSEMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/search/store.vue?macro=true";
import { default as searchbNzn4Q4K8SMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/search.vue?macro=true";
import { default as DescriptionSectionV3kHowxprTMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue?macro=true";
import { default as HeroSectionVkGLejGRXSMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue?macro=true";
import { default as indexoa1hpKfHeeMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue?macro=true";
import { default as ChooseClientdfZ3KsVeALMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue?macro=true";
import { default as ChooseClientElement1iZLpVpDZPMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue?macro=true";
import { default as ClientsElement5WZWKX53EgMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue?macro=true";
import { default as ClientsSectionRcFhjzaMJPMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue?macro=true";
import { default as HeroHeaderKQTY1kJ2YsMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue?macro=true";
import { default as goToJoinRawlplugSectionzPCWMav9JrMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts?macro=true";
import { default as JoinRawlplugXaPcIpku9sMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue?macro=true";
import { default as JoinRawlplugFormhQbtDEzTpUMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue?macro=true";
import { default as JoinRawlplugModal94BG2QqpafMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue?macro=true";
import { default as JoinRawlplugStickyQBQm8LQsiKMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue?macro=true";
import { default as SlideCtaHy3aHIgGbBMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue?macro=true";
import { default as SlideLeftNCr8wDo70GMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue?macro=true";
import { default as SlideRightJryZdlnPIKMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue?macro=true";
import { default as SliderPaginationzQYEFVQ1GkMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue?macro=true";
import { default as SliderSectionGLLXVI6YhxMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue?macro=true";
import { default as slidesh6MtRCokYsMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts?macro=true";
import { default as disableAutoScrollOnLastSection3QJL8RAojTMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts?macro=true";
import { default as usePagination7h8HIEwBAnMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts?macro=true";
import { default as useStyleWithHeaderHeightOaBSqh3mkcMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts?macro=true";
import { default as indexOlII6F30QUMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/index.vue?macro=true";
import { default as constantsI3uSUYDRJZMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js?macro=true";
import { default as FullPageqSF9t65mucMeta } from "/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue?macro=true";
import { default as T3PageVuo7poP9YXMeta } from "/home/ecomadm/app/releases/194/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93GC0cXHahTLMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-cms/pages/[...slug].vue")
  },
  {
    name: "account",
    path: "/:locale()/account",
    meta: accountBhLZjRBUFLMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account.vue"),
    children: [
  {
    name: addressesFA4zYmifppMeta?.name ?? "locale-account-addresses",
    path: "addresses",
    meta: addressesFA4zYmifppMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/addresses.vue")
  },
  {
    name: companyzydbg0cTlLMeta?.name ?? "locale-account-company",
    path: "company",
    meta: companyzydbg0cTlLMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/company.vue")
  },
  {
    name: contactQLuI1rxfTEMeta?.name ?? "locale-account-contact",
    path: "contact",
    meta: contactQLuI1rxfTEMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/contact.vue")
  },
  {
    name: "locale-account",
    path: "",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/index.vue")
  },
  {
    name: listsy6b9IcpnjyMeta?.name ?? "locale-account-lists",
    path: "lists",
    meta: listsy6b9IcpnjyMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/lists.vue")
  },
  {
    name: "offer",
    path: "offer/:id/:variantId",
    meta: _91variantId_93jyvOZDhnyWMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/offer/[id]/[variantId].vue")
  },
  {
    name: indexJo3UtOHJVrMeta?.name ?? "locale-account-offers",
    path: "offers",
    meta: indexJo3UtOHJVrMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/offers/index.vue")
  },
  {
    name: "locale-account-order-slug",
    path: "order/:slug+",
    meta: _91_46_46_46slug_93V4yKoYOwakMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue")
  },
  {
    name: orderskKP5yrb6FcMeta?.name ?? "locale-account-orders",
    path: "orders",
    meta: orderskKP5yrb6FcMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/orders.vue")
  },
  {
    name: payments_45and_45billingAcwWK8t5ssMeta?.name ?? "locale-account-payments-and-billing",
    path: "payments-and-billing",
    meta: payments_45and_45billingAcwWK8t5ssMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue")
  },
  {
    name: "locale-account-permissions-add-user",
    path: "permissions/add-user",
    meta: add_45user105NWPRRZwMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue")
  },
  {
    name: "locale-account-permissions-roles-id-edit",
    path: "permissions/roles/:id()/edit",
    meta: edit5FginiPqg9Meta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue")
  },
  {
    name: "locale-account-permissions-roles",
    path: "permissions/roles",
    meta: indexpOHNvJ0Y1GMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue")
  },
  {
    name: "locale-account-permissions-roles-new",
    path: "permissions/roles/new",
    meta: newDj1OCi6BAbMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue")
  },
  {
    name: "locale-account-permissions-users-id-edit",
    path: "permissions/users/:id()/edit",
    meta: edithyVEzR5ZBqMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue")
  },
  {
    name: indexePQEtlkT5NMeta?.name ?? "locale-account-permissions-users",
    path: "permissions/users",
    meta: indexePQEtlkT5NMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue")
  },
  {
    name: profile841olOrNBpMeta?.name ?? "locale-account-profile",
    path: "profile",
    meta: profile841olOrNBpMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/account/profile.vue")
  }
]
  },
  {
    name: "category",
    path: "/:path/c/",
    meta: category6cZNvaUMeAMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/category.vue"),
    children: [
  {
    name: "category",
    path: ":slug+",
    meta: _91slug_93eQkW2qJTC1Meta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue")
  }
]
  },
  {
    name: checkoutLhZ1rKIAaIMeta?.name ?? "locale-checkout",
    path: "/:path/checkout/",
    meta: checkoutLhZ1rKIAaIMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/checkout.vue"),
    children: [
  {
    name: cartdniQ1d6g8eMeta?.name ?? "locale-checkout-cart",
    path: "/:path/checkout/cart",
    meta: cartdniQ1d6g8eMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue")
  },
  {
    name: "checkout-delivery-and-payment",
    path: "/:path/checkout/delivery-and-payment",
    meta: delivery_45and_45paymentCDsqvTYrKaMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue")
  },
  {
    name: "checkout-summary",
    path: "/:path/checkout/summary",
    meta: summaryzG6fw341tCMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue")
  }
]
  },
  {
    name: "locale-customer-account-createPassword",
    path: "/:locale()/customer/account/createPassword",
    meta: createPasswordeolWbCZSfiMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue")
  },
  {
    name: "locale-customer-account-resetPassword",
    path: "/:locale()/customer/account/resetPassword",
    meta: resetPassword0nUElijrt4Meta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue")
  },
  {
    name: "locale-event",
    path: "/:locale()/event",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/event/index.vue")
  },
  {
    name: "locale-newsletter",
    path: "/:locale()/newsletter",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/newsletter/index.vue")
  },
  {
    name: "product",
    path: "/:path/p/:slug",
    meta: productlKqbvJQ5TpMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/product.vue")
  },
  {
    name: registerl1WuNp4oqMMeta?.name ?? "locale-register",
    path: "/:locale()/register",
    meta: registerl1WuNp4oqMMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-customer/pages/[locale]/register.vue")
  },
  {
    name: "ecom-redirect-resolve",
    path: "/:locale/r/:type/:slug",
    meta: resolveWUiEKTzX7IMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/resolve.vue")
  },
  {
    name: searchbNzn4Q4K8SMeta?.name ?? "locale-search",
    path: "/:path/search",
    meta: searchbNzn4Q4K8SMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/search.vue"),
    children: [
  {
    name: cmsyh3P5s3Bs3Meta?.name ?? "locale-search-cms",
    path: "/:path/search/cms",
    meta: cmsyh3P5s3Bs3Meta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/search/cms.vue")
  },
  {
    name: storeIKRif2aTSEMeta?.name ?? "locale-search-store",
    path: "/:path/search/store",
    meta: storeIKRif2aTSEMeta || {},
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-ecom/pages/[locale]/search/store.vue")
  }
]
  },
  {
    name: "locale-technical-helpdesk-components-DescriptionSection",
    path: "/:locale()/technical-helpdesk/components/DescriptionSection",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue")
  },
  {
    name: "locale-technical-helpdesk-components-HeroSection",
    path: "/:locale()/technical-helpdesk/components/HeroSection",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue")
  },
  {
    name: "locale-technical-helpdesk",
    path: "/:locale()/technical-helpdesk",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClient",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClient",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClientElement-ChooseClientElement",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsElement-ClientsElement",
    path: "/:locale()/welcome/components/ClientsSection/ClientsElement/ClientsElement",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsSection",
    path: "/:locale()/welcome/components/ClientsSection/ClientsSection",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue")
  },
  {
    name: "locale-welcome-components-HeroHeader-HeroHeader",
    path: "/:locale()/welcome/components/HeroHeader/HeroHeader",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-goToJoinRawlplugSection",
    path: "/:locale()/welcome/components/JoinRawlplug/goToJoinRawlplugSection",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplug",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplug",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugForm",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugForm",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugModal",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugModal",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugSticky",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugSticky",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideCta",
    path: "/:locale()/welcome/components/slider/SlideCta",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideLeft",
    path: "/:locale()/welcome/components/slider/SlideLeft",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideRight",
    path: "/:locale()/welcome/components/slider/SlideRight",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderPagination",
    path: "/:locale()/welcome/components/slider/SliderPagination",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderSection",
    path: "/:locale()/welcome/components/slider/SliderSection",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue")
  },
  {
    name: "locale-welcome-components-slider-slides",
    path: "/:locale()/welcome/components/slider/slides",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts")
  },
  {
    name: "locale-welcome-composables-disableAutoScrollOnLastSection",
    path: "/:locale()/welcome/composables/disableAutoScrollOnLastSection",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts")
  },
  {
    name: "locale-welcome-composables-usePagination",
    path: "/:locale()/welcome/composables/usePagination",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts")
  },
  {
    name: "locale-welcome-composables-useStyleWithHeaderHeight",
    path: "/:locale()/welcome/composables/useStyleWithHeaderHeight",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts")
  },
  {
    name: "locale-welcome",
    path: "/:locale()/welcome",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/index.vue")
  },
  {
    name: "locale-welcome-vendors-fullpage-constants",
    path: "/:locale()/welcome/vendors/fullpage/constants",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js")
  },
  {
    name: "locale-welcome-vendors-fullpage-FullPage",
    path: "/:locale()/welcome/vendors/fullpage/FullPage",
    component: () => import("/home/ecomadm/app/releases/194/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue")
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/home/ecomadm/app/releases/194/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue")
  }
]